import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"
import PageContentHeadSwitch from "../components/PageCententHeadSwitch"

import Link from "../components/LinkWithTransitionLock";
// import LinkAnimation from "../components/LinkAnimation";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()


    // clearInterval(project_intervalId);

    let cover = document.querySelector('.cover_slide');
    let project_intervalId = setInterval( function(){
      let img = document.querySelectorAll('.cover_slide .cover_item')[0];
      cover.appendChild( img );
    }, 1500 );

    let cover_slide_b = document.querySelector('.cover_slide_b');
    let project_intervalId_b = setInterval( function(){
      let img = document.querySelectorAll('.cover_slide_b .cover_item')[0];
      cover_slide_b.appendChild( img );
    }, 1500 );
    

    var project_cover = document.querySelectorAll('.cover_slide');
    project_cover.forEach((b) => b.addEventListener("click", function(){
      clearInterval(project_intervalId);
      clearInterval(project_intervalId_b);
    }));

    document.querySelector('.home_logo').addEventListener('click', function(){
      clearInterval(project_intervalId);
      clearInterval(project_intervalId_b);
    });

  }
 
  componentWillUnmount() {
    
  }


  render() {

    var cover_item_1 = require('../images/project_a_muse_1-2.jpg');
    var cover_item_2 = require('../images/project_a_muse_2-2.jpg');
    var cover_item_3 = require('../images/project_a_muse_3.jpg');
    var cover_item_4 = require('../images/project_a_muse_4.jpg');
    var cover_item_5 = require('../images/project_a_muse_5-2.jpg');
    var cover_item_6 = require('../images/project_a_muse_6.jpg');


    var cover_item_7 = require('../images/project_a_gift_1.jpg');
    var cover_item_8 = require('../images/project_a_gift_2.jpg');
    var cover_item_9 = require('../images/project_a_gift_3.jpg');
    var cover_item_10 = require('../images/project_a_gift_4.jpg');
    var cover_item_11 = require('../images/project_a_gift_5.jpg');

    var next_visual = require('../images/home/project_b.png');
    return (
      // <>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">策展人/沈伯丞
            <PageContentHeadSwitch/>
          </h2>
          <h1 className="main_title">成為我的繆思，獻給他的禮物</h1>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_a">
            <div className="column_3">
              <div className="context">
                <h5 className="main">藝術計畫作為縫補社會間隙的黏著劑。藝術家作為黏著的媒介。</h5>
                <p>公共藝術能替公眾做些什麼？運用傳統生活中不可或缺的「埕」的概念，或許能提供解答。「廟埕」、「稻埕」一類的空間，是建築和建築實體之間空曠的場域，然而另一方面又是社群彼此相遇、互動、聯繫情感的地方。例如廟會、戲劇、祭祀、曬穀乃至於閒話家常的這些日常活動，建構了共同的生命記憶，讓「埕」儼然擁有精神性上的意涵，得以長期而穩定地，讓地方社群發展屬於自己的文化。</p>
                <p>台北市社宅基地彼此連成包圍市中心的外圍環圈，也同樣帶著這種作為過渡場域的留白特質。這是弱勢族群、來自各地的移民，要遷往市中心求得更好生活的過渡站。那麼，是否能使這樣的地方化為「相遇的埕」，移民得以在此共構彼此的情感和認同，匯聚踏出下一步的動力，成就一趟以希望為名的遷徙？</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_b row_gallery">
            <div className="column_8 gallery_wrap ">
              <figure className="figure project_sub size_m gallery_animate">
                <Link to='/project_a_muse' className="project_cover cover_slide cover_slide_a">
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_1 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_2 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_3 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_4 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_5 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_6 + ")" } }></div>
                </Link>
                <Link to='/project_a_muse' className="project_sub_title">
                  <h4>台北萬華 最美的是人</h4>
                  <h6>林立青</h6>
                </Link>
              </figure>
              <figure className="figure project_sub size_s gallery_animate">
                <Link to='/project_a_gift' className="project_cover cover_slide cover_slide_b">
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_7 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_8 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_9 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_10 + ")" } }></div>
                  <div className="cover_item" style={ { backgroundImage: "url(" + cover_item_11 + ")" } }></div>
                </Link>
                <Link to='/project_a_gift' className="project_sub_title">
                  <h4>《魏絲貝斯計畫》</h4>
                  <h6>何孟娟</h6>
                </Link>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_a_c">
            <div className="column_5">
              <div className="context">
                <h5>策展計畫遂以<strong>「成為我的繆思，獻給他的禮物」</strong>為題。分為兩部份，<i>「成為我的謬思」</i>意指參與計畫的民眾，和藝術家建立的互動關係，以及後者因此獲得靈感製作的作品；<i>「獻給他的禮物」</i>則希望透過這項計畫，彰顯一種微型卻照護弱勢群體的社會支持系統。</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_c">
            <div className="column_4">
              <div className="context">
                {/* <p>藝術家尋找在萬華地區幫助遊民的<strong>善心人士</strong>，邀請他們分享故事，並將這聊天的過程，以拍照和側寫的方式記錄下來。</p>
                <p>藝術家利用從談話中獲得的靈感創作，並將手稿於線上呈現，也等於將社會既存的善念透過網路被更多人看見。藉此嘗試縫補社會的間隙，在當代社會中創造新的可能。</p> */}
                <p>其中一位藝術家林立青尋找萬華地區的善心人士，邀請他們分享故事，並將過程以拍照和側寫的方式記錄下來，讓社會既存的善念透過網路被更多人看見，藉此嘗試縫補社會的間隙，在當代社會中創造新的可能。而另一位藝術家何孟娟，則展出她2013～2016年，進駐紐約藝術家公寓魏斯貝斯（Westbeth Artists Housing）時製作的紀錄影片。受訪的高齡藝術家們，分享了生命經驗，以及居住在這租金低廉卻位於精華地段的公寓的點滴，如鏡子一般，遙相對望希望透過藝術的力量創新社區文化的台北市社宅，深具啟發意義。</p>
              </div>
            </div>
          </section>

        </div>


        <div className="head_intro_content headroom">
          <h5 className="name">沈伯丞</h5>
          <h6 className="title">藝評策展人 國立臺北藝術大學、國立臺灣藝術大學助理教授</h6>
          <p className="bio">國立台南藝術大學藝術創作與理論博士，前中華經濟研究院輔助研究員。現為策展人與評論家，並於國立臺北藝術大學、國立臺灣藝術大學兼任教書。 從化學到藝術史，從藝術史到經濟學，再從經濟學回到當代藝術理論，「知識」於沈伯丞是一種可持續遊走、玩耍的思維遊戲。從策展公司到畫廊，再從畫廊到智庫，又從智庫回學院，再從學院人轉為策展人，「藝術」於沈伯丞是人生轉折的每一個理由。</p>
        </div>

        <div className="page_footer">
          <Link to="/project_b" className="next_page">
            <div className="next_title">單人部落</div>
            <div className="next_owner">策展人/方彥翔</div>
            <div className="next_visual" style={ { backgroundImage: "url(" + next_visual + ")" } }></div>
          </Link>
        </div>

      </div>

      // </>
    )

  }

}


export default Page

